import { Translation } from 'react-i18next';

let _Settings = {
    general:{
      showFooter: false,
      embedded: false,
      fontFamily: "TTFirsNeue-Regular"
      //primaryColor: '#ffffff',
      //secondaryColor:'none'
    },
    chatWindow: {
      defaultOpen: true,
      showScrollbar: true
    },
    tooltip: {
      mode: "CLOSE",
      text: ""
    },
    header: {
      title:(
        <Translation>{
          (t, { i18n }) => 
          <div style={{cursor: "pointer", margin: 0, fontSize: 20, fontWeight: "bold", lineHeight:'40px'}}
                onClick={() => window.open("https://sitronics.com")}>
                  {t("header")}
         </div>
        }
        </Translation>
      ),
      showAvatar: true,
      avatar: './assets/favicon.ico',
      buttons: []
    },
    botBubble: {
      animate: true,
      showAvatar: true,
      dangerouslySetInnerHtml: true,
      //avatar: './assets/favicon.ico',
    },
    userBubble:{
      dangerouslySetInnerHtml: true
    },
    event:{
      rcbPreInjectMessage:true
    },
    chatHistory:{
      disabled: true
    }
  }
  const Styles = {
    chatWindowStyle: {
      top:'calc(50% - 275px)',
      left: 'calc(50% - 175px)',
      position: 'fixed'
    },
    headerStyle:{
      backgroundImage: 'none'
    },
    userBubbleStyle: {  
      borderRadius: '15px 15px 0  15px',
       padding:'5px 10px 7px 10px',
      backgroundColor: 'rgb(245, 245, 245)',
      color: '#000'
     },
    sendButtonStyle: {
      backgroundImage: 'linear-gradient(-100deg,#2dc3ff,#a85cfd)'
    },
    botBubbleStyle: {
      backgroundColor: '#fff',
      color: '#000',
      borderRadius: '15px 15px 15px 0',
      border: '.5px solid #adadad',
      padding:'5px 10px 7px 10px',
      alignSelf: 'end'
    },
    chatInputAreaStyle: {
      overflowY:'auto'
    },
    chatInputAreaFocusedStyle: {
      boxShadow:'none'
    },
    toastPromptContainerStyle: {
      position: 'fixed',
      bottom:'15%'
    },
    toastPromptStyle: {
      color: 'rgb(66,176,197)',
      borderColor: '#42b0c5'
    },
    toastPromptHoveredStyle:{
      backgroundColor: 'rgba(66,176,197)',
      color:'#fff'
    }
  }
  

  export { _Settings, Styles }
  