import  { useContext } from 'react'
import { ChatContext } from '../App'
import { useTranslation } from "react-i18next";


function DocLinks({ content, context }) {
    const { onGalleryItemClick } = useContext( ChatContext );
    const { t, i18n } = useTranslation();

    if(!content || !content.length) return <></>
    return (
       < >
           <div className="bot-message-bottom-items">
                <div className = "tooltip">
                    <a download = { content[0].title } href = { content[0].url }><div className = "doc-icon"/></a>
                    <span className = "tooltiptext">{t('load_document',{title: content[0].title })}</span>
                </div>
                { context ?
                    (<>
                        <div className = "tooltip">
                            <a download = { context.context_title } href = { context.context_url } ><div className = "context-icon"/></a>
                            <span className = "tooltiptext">{t('load_context')}</span>
                       </div>
                       <div className = "tooltip">
                            <div className="view-icon" onClick={()=>{ onGalleryItemClick(context.context_url, "context", context)}}></div>
                            <span className = "tooltiptext">{t('view_context')}</span>
                       </div>
                    </>)
                    : (<></>)
                }
           </div>
       </> 
    )
}

export default DocLinks