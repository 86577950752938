import sanitizeHtml  from 'sanitize-html';


const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const cleanHtml = ( input ) => {
    let cleanInput = sanitizeHtml(input);
        if(cleanInput.trim().length == 0){
           return {dirty: true}
        }
        else {
            return {dirty: false, cleanInput}
        }
}

const messageTimeMarker = ( timestamp ) => {
      let ts = new Date(timestamp);
      let mn = ts.getMinutes();
      mn = mn < 10 ? `0${mn}` : mn;
      return `${ts.getHours()}:${mn}`;
}

//get context url to download
const contextObject = ( context ) => {
    return context ? {context,context_url: 'data:text/plain;charset=utf-8,' + encodeURIComponent(context),context_title: 'context'} : null;
}

export { uuidv4, cleanHtml, messageTimeMarker, contextObject }