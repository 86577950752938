import './styles/App.css';
import ChatBot,{  ChatBotProvider } from "react-chatbotify";
import { ServiceRequest, ServiceResponse, loadContent } from './service/service';
import { _Settings, Styles } from './config/chat.settings'
import { uuidv4, cleanHtml, messageTimeMarker } from './utils'
import {  useEffect, Suspense, useState, createContext } from 'react';
import { ConfigHook } from './service/configHook';
import { ErrorHook } from './service/errorHook';
import { useTranslation } from "react-i18next";
import MediaPopoverContainer from './components/MediaPopoverContainer';
import Gallery from './components/Gallery';
import Error, {detectNetworkError} from './config/error';
import DocLinks from './components/DocLinks';
import ScrollToBottomButton from './components/ScrollToBottomButton';
import CancelRequestBtn from './components/CancelRequestBtn';
import BotBubble from './components/BotBubble'


export const ChatContext = createContext(null);

function App() {
  let errorHandler = null, requestId = null;
  const answer = {
    message: null,
    timestamp: '',
    content: [],
    context: null,
    options: null,
    timeMarker: null
  }
  const { t, i18n } = useTranslation();

  const [isPopoverActive, setPopoverActive] = useState(false);
  const [ contentData, setContentData ] = useState({ src: null, type:null, context: null });
  const [ userId, setCurrentUser ] = useState(null);
  const [ errorEvent, setError] = useState(null);
  const [ cancelBtn, setCancelBtn] = useState({ id: null, show: false});

  const handlePopoverOpen = (src, type, context) => {
    setPopoverActive(true);
    setContentData({ src, type, context })
  };
  const handlePopoverClose = () => {
    setPopoverActive(false);
  };  
  
  
  useEffect(() => {
    window.addEventListener('offline',(event) => {
      console.log(`error offline`)
      setError({m: t('error.network_problem'), t: new Date()});
  })
    function handlePreInjectMessage(event)  {
      if(event.data.message.type == 'object' && event.data.message.content.type.name == 'DataMarker') return;
      let ts = messageTimeMarker(event.data.message.timestamp);

      if( event.data.message.sender =='user'){
        let cleanUserInput = cleanHtml(event.data.message.content);
        if(cleanUserInput.dirty){
          event.preventDefault();
        }
        else event.data.message.content = event.data.message.content = cleanUserInput.cleanInput + "<div class='message-timestamp'>"+ ts +"</div>"
      }
    };

    window.addEventListener("rcb-pre-inject-message", handlePreInjectMessage);
    return () => {
      window.removeEventListener("rcb-pre-inject-message", handlePreInjectMessage);
    };

    
  }, []);

  async function sendUserMessage( arg ) {
    try {
      console.log(`sendUserMessage ${arg.userInput}`)
      let cleanUserInput = cleanHtml(arg.userInput);
      if( cleanUserInput.dirty ){
        console.log(`sendUserMessage dirty`)
        errorHandler = Error.DANGEROUS_CODE;
      }
      else {
        let result = await ServiceRequest({ text: cleanUserInput.cleanInput, user_id: userId });
        if( result && result.error ){
          errorHandler = result;
        }
        else {
            requestId = result
        }
      }
    } catch(err) {
      console.log(`error while waiting ServiceRequest: ${arg.userInput}`);
      errorHandler = Error.ERROR
    }
  }

  function getLoggedUser(){
    let user_id = localStorage.getItem('known_user');
    if( !user_id ){
      let current_timestamp = new Date().getTime();
      user_id = uuidv4() + '_' + current_timestamp;
      localStorage.setItem('known_user', user_id);
      console.log(`new user: ${user_id}`)
    }
    else {
      console.log(`known user:  ${user_id}`)
    }
    return user_id;
  }

	const flow={
		start: {
			message: async() => {
        setCurrentUser(getLoggedUser());
        return t('welcome_message')
      },
      component:<><ScrollToBottomButton/><CancelRequestBtn /></>,
			path: "dialog"
		},
 		dialog: {
			message: async (params) => {
        console.log(`dialog: message ${params.userInput}`)
       // await params.injectMessage(<DataMarker timestamp = { Date.now() } validate = { true } sender={'dialog'} input={params.userInput}/>, "user");
        //обнулить предыдущий запрос
        requestId = null;
        try {
          //запрос пользователя
          await sendUserMessage(params);
          if( errorHandler ) { 
            setError({m: t(errorHandler.message), t: new Date() })
            return;
          }
          
          //ожидание ответа
          console.log(`dialog: message requestId ${requestId}`)
          let tid = setTimeout(async () => {
              setCancelBtn({ id: requestId, show: true}); 
          },15*1000)
          let res = await ServiceResponse({ requestId, params });

          setCancelBtn({ id: null, show: false});
          clearTimeout(tid);
          
          if( res && res.error ) {
            if( res.error == "user_canceled") return '';
            if( res.message){
              setError({m: t(res.message, res.key), t: new Date()});
              return ;
            }
            if( res.error.message) {
              setError({m: res.error.message, t: new Date()});
              return ;
            }
            else {
              setError({m: t('error.get_answer'), t: new Date()});
              return; 
            }
          }

          //if has content
          if( res.content && res.content.length ){
              answer.content = res.content;
          }

          //answer has options
          if( res.options ){
            answer.options = res.options;
          }

          //данные для отображения в компоненте сообщения бота
          answer.message = res.answer;
          answer.timestamp = res.timestamp;
          answer.context = res.context;
          answer.timeMarker = res.timeMarker;
          return ;

        } catch(err) {
          console.log(`error while waiting ServiceResponse: ${params.userInput}`);
          setError( {m: t('error.get_answer'), t: new Date()})
          return;
        }
			},
      component: async() => {//компонента сообщения бота
        if( (!answer.message || !answer.message.length) && 
            (!answer.content || !answer.content.length) &&
             (!answer.context || !answer.context.length)) return;
        let readyContent = await loadContent(answer.content);
        let docContent = readyContent?.filter(c => c.content_type == 'doc');
        let media   = readyContent?.filter(c => c.content_type != 'doc');
        return  <BotBubble message = { answer.message } doc = { docContent } media = { media } timestamp = { answer.timeMarker } context = {answer.context}/>
      },
      options: async() => {
        console.log(`OPTIONS: ${JSON.stringify(answer.options)}`)
        return answer.options ? answer.options : [] 
      },
			path: () => {
        errorHandler = null;
        answer.options = null;
        answer.content = [];
        answer.context = null;
        answer.message = null;
        answer.timestamp = '';
        answer.timeMarker = null;
        return  "dialog"
      }
		}
	}
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
    <ChatBotProvider>
      <ChatContext.Provider 
        value = {{
          currentUserId: userId,
          onGalleryItemClick:  handlePopoverOpen,
          cancelBtn
         }}>
        <ConfigHook />
        <ErrorHook eventMessage = { errorEvent }/>
        <ChatBot flow = { flow } settings= { _Settings } styles = { Styles } />
        {isPopoverActive && (
            <MediaPopoverContainer onClose = { handlePopoverClose } content = { contentData }/>
          )}
      </ChatContext.Provider>
    </ChatBotProvider>
    </Suspense>
  );
}

export default App;

