import MediaItem from './MediaItem'
import {useRef, useEffect} from "react"

function MediaPopoverContainer(props){
    const { onClose, content } = props;
    const { src, type, context } = content;
    const inputRef = useRef(null);

   /* useEffect(()=>{
      console.log(`popover`)
      if(type == 'context'){

        let ctx = Array.isArray(context) ? context[0] : context;
        let positionStart = src.indexOf(ctx.slice(0,456));
        if(positionStart == -1) positionStart = 0;
        inputRef.current.focus()
        inputRef.current.setSelectionRange(positionStart, positionStart+ctx.length);
        inputRef.current.scrollTo(0,positionStart)
          
      }
    },[inputRef])*/

    let element = type == 'context' ?
          <textarea ref = { inputRef } value={ context.context } disabled = {true}/> :
          <MediaItem src = { src } type = { type } />

    return (
      <div className='media-popover-container'>
        <div className='chat-media'>
           {  element }
        </div>
        <button type="button" className="close" style={{backgroundImage: 'url(./assets/close.svg)'}} onClick={onClose}></button>
      </div>
    )
}

export default MediaPopoverContainer