import { useState, useEffect, useContext } from 'react'
import { CancelRequest } from '../service/service';
import { useTranslation } from "react-i18next";
import { ChatContext } from '../App'
import { useToasts } from 'react-chatbotify';

function CancelRequestBtn(){
    const { t } = useTranslation();
    const [ showBtn, setShowBtn] = useState({display:'none'});
    const { cancelBtn } = useContext( ChatContext );
    const { showToast } = useToasts();

    useEffect(()=>{
        console.log(`show cancel ${cancelBtn.show}`)
        if( cancelBtn.show ) setShowBtn({display: 'block'});
        else setShowBtn({display: 'none'});
    }, [cancelBtn])

    function cancel(){
        return CancelRequest(cancelBtn.id)
        .then(res => {
            if(res && res.error){
                showToast(t(res.message));
            }
            console.log(`canceled request id ${cancelBtn.id}, result:  ${JSON.stringify(res)}`);
            setShowBtn({display:'none'});
        }).catch(err=>{
            console.log("cancel btn error")
            showToast(t('error.cancel_request_error'))
        })
    }
    return (
       <div className = "cancel-request-btn" style =  { showBtn }>
           <button onClick = { cancel }>{t('cancel_request')}</button>
       </div>
    )
}

export default CancelRequestBtn