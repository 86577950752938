
import { useTranslation } from "react-i18next";

//title - file name (used only for type= doc)
function MediaItem(props){
    const { type, src, title, ikey, class1, onClick } = props;
    const { t } = useTranslation();

    let element = <></>;
    let simple_type = 'doc' //type= 'application/<some extension>'
    if( type.startsWith('image') ) simple_type = 'image';
    if( type.startsWith('video') ) simple_type = 'video';
    if( type == 'error' ) simple_type = 'error';

    switch( simple_type ){
        case 'image':
            element = <img key = { ikey } src = { src } className= { class1 } onClick={ onClick }/>
            break;
        case 'video':
            if( class1 == 'content-image' || class1 == 'single-content-image') //for gallery hide controls and show play image placeholder
                element = 
                    <div style = {{position:'relative'}} key = { ikey }>
                        <video  className= { class1 } onClick={ onClick } >
                            <source src = { src } type = { type } />
                        </video>
                        <img  className = 'video-placeholder' onClick={ onClick }></img>
                    </div>
            else //for MediaPopoverContainer show controls
                element =
                    <video controls preload="none" className= { class1 } onClick={ onClick } >
                        <source src = { src } type = { type }/>
                    </video>
            break;
       /* case 'doc':
            element =
               <div className = "rcb-message-prompt-text media-item-doc" key = { ikey }>
                  <a download = { title } href = { src } style = {{ color: '#000'}}>{ t('gallery.download_file') }{ ' ' }{ title }</a>
               </div>
            break;*/
        case 'error':
            element = 
                <div key = { ikey } style = {{cursor: 'default'}} className = "media-item-doc rcb-message-prompt-text ">
                   {t('error.load_content')}
                </div>
    }
    return ( element );
}

export default MediaItem