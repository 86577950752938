import { useState} from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Gallery from './Gallery'
import DocLinks from './DocLinks'


function BotBubble({ message, media, doc, timestamp, context}){

    return (
        <>
            <div className="rcb-bot-message rcb-bot-message-entry ml0" >
                <div className = 'bot-message-bordered'>
                    { message && message.length && ( <Markdown remarkPlugins={[remarkGfm]}>{ message }</Markdown> )}
                    <div className = "doc-timestamp-area">
                        {doc && doc.length ? <DocLinks content = { doc }  context = { context }/> : <></>}
                        <div className='message-timestamp'>{timestamp}</div>
                    </div>
                </div>
                {media  && media.length ? <Gallery content = { media } /> : <></>}
            </div>
           
        </>
    )
}

export default BotBubble